<template>
    <div class="type-list">
        <ul>
            <li
                :class="{'active': currentIndex === index}"
                v-for="(item, index) in typeList"
                :key="index"
                @click="changeType(index)"
            >
                <div class="con">
                    <span class="tit">{{ item.name }}</span>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        typeList: {
            type: Array,
        },
        resetIndex: {
            type: [String],
        },
    },
    data() {
        return {
            currentIndex: 0,
        };
    },
    watch: {
        resetIndex() {
            this.currentIndex = 0;
        },
    },
    methods: {
        changeType(index) {
            this.currentIndex = index;
            this.$emit('changeTable', index);
        },
    },
};
</script>
<style lang="stylus" scoped>
.type-list
    li
        position relative
        .con
            width 1.64rem
            height .56rem
            border 1px solid #D7D7D7
            text-align center
            position relative
            margin-bottom .2rem
            overflow hidden
            cursor pointer
            .tit
                line-height .56rem
                font-size .16rem
            .status
                width .56rem
                height .56rem
                position absolute
                left -.28rem
                top -.28rem
                transform rotate(-45deg)
                -webkit-transform rotate(-45deg)
                -moz-transform rotate(-45deg)
                line-height .92rem
                font-size .12rem
                color #fff
        &.active
            background url('./../../../../assets/images/bbgl/frame_blue.png') no-repeat
            background-size 100% 100%
            .con
                border none
                color #5588f1
            // &:after
            //         content " "
            //         width .14rem
            //         height .2rem
            //         position absolute
            //         right: .02rem;
            //         top .18rem
            //         background url('./../../../../assets/images/bbgl/arrow_blue.png')
            //         background-size: 100% 100%;

</style>